import React from "react"
import { Link } from "gatsby"

const HowItWorks = () => (
<ul className="steps">
  <li className="step-item is-completed is-success">
    <div className="step-marker">
      <span className="icon">
        <i className="fa fa-check"></i>
      </span>
    </div>
    <div className="step-details is-primary is-completed">
      <p className="step-title">Step 1</p>
      <p>This is the first step of the process.</p>
    </div>
  </li>
  <li className="step-item is-info is-completed">
    <div className="step-marker"></div>
    <div className="step-details">
      <p className="step-title">Step 2</p>
      <p>This is the second step. You get here once you have completed the first step.</p>
    </div>
  </li>
  <li className="step-item is-warning is-completed">
    <div className="step-marker">3</div>
    <div className="step-details">
      <p className="step-title">Step 3</p>
      <p>This is the third step. One more step before the end.</p>
    </div>
  </li>
  <li className="step-item is-danger is-active">
    <div className="step-marker">
      <span className="icon">
        <i className="fa fa-flag"></i>
      </span>
    </div>
    <div className="step-details">
      <p className="step-title">Step 4</p>
      <p>Final step. You have completed all the previous steps and end the process.</p>
    </div>
  </li>
</ul>
)

export default HowItWorks;


